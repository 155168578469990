export default async function importPageComponents(PageComponents, props) {
  return await Promise.all(
    PageComponents.map(PageComponent => {
      return import(`../componentsList/${PageComponent.componentName}`)
    })
  )
    .then(components => {
      return components.map((component, index) => {
        const options = PageComponents[index].options
        return () =>
          component.default({
            variantName: PageComponents[index].variantName,
            options,
            ...props
          }) // PROPS !!! haved to be passed here. they are not taking in the JSX. Unknown Issue
      })
    })
    .catch(error => {
      console.error(`Could not Import Modules - ${error}`)
    })
}

/* 
=== Dyanmic Importer Notes ===

This function takes in a list of component names and goes into 
the stories folder and imports the correct components based on 
file name/ component name.

it uses a promise all function to fetch the module/components.

the map function creates an array of components that gets returned to be used.

*component.default() must be return in a function

it looks like this listOfFunctionalComponents = [ƒ, ƒ, ...etc]

*/
