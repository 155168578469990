import slugify from 'slugify'

export default (navLinks, configData) => {
  const generalNavLinksConfig = [
    configData?.about?.order,
    configData?.menu?.order,
    configData?.events?.order,
    configData?.gallery?.order,
    configData?.contact?.order
  ] // create an array of the general page orders. ? is chaining operator to prevent crash if undefined or null.

  return navLinks.map((link, index) => {
    const linkHasHashes = link.includes('#')

    const parsedLink = linkHasHashes
      ? slugify(link.split('#')?.[0], { lower: true }) + `#${link.split('#')?.[1]}`
      : slugify(link, { lower: true })

    return {
      ctaName: link,
      ctaLink: parsedLink,
      order: generalNavLinksConfig[index] !== null ? generalNavLinksConfig[index] : index + 1.5 // grab all the general page order. if not null use. if null default to same order
    }
  })
}

// structures the navlinks into object form for customising
// i.e navLinks = ['About Us', 'menu','events', gallery','contact-us']
// returns [{ctaName: About Us, ctaLink:'about-us', order: 0.5},{..rest}]

// ctaName is the label
// ctaLink is the actul link url suffix
// order is used to set the order of the nav bar ready for additonal links to be added
