import React from 'react'
import Form from '../components/ContactForm/v1/Form'
import ShadowerComponent4 from '../../../unionLeagueCafe/src/gatsby-theme-minimal/components/General/ShadowerComponent4'

export default function ContactFormV1(props) {
  return (
    <>
      <Form {...props} />
    </>
  )
}
