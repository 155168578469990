import React, { useContext } from 'react'
import { Box } from 'theme-ui'
import { store } from '../../../Context/Store'
import Lightbox from 'react-image-lightbox'

export default function GlobalLightBox() {
  const { globalState, dispatch } = useContext(store)
  const { isOpen, uri, caption } = globalState?.lightBox

  const closeLightBox = () => dispatch({ type: 'CLOSE_LIGHTBOX' })

  if (isOpen) {
    return (
      <Lightbox
        wrapperClassName="globalLightbox"
        reactModalStyle={{ content: { zIndex: '5000' } }}
        mainSrc={uri}
        caption={caption}
        onCloseRequest={closeLightBox}
      />
    )
  } else {
    return ''
  }
}
