import React, { useContext } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import { store } from '../../../Context/Store'

export default function GlobalModal() {
  const { globalState, dispatch } = useContext(store)
  const { modalOpen, title, subtitle, text, html } = globalState?.modal

  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' })

  const styles = {
    variant: 'customStyles.globalModal'
  }
  if (modalOpen) {
    return (
      <Box sx={{ ...customStyles.container, ...styles }} className="globalModal">
        <Box sx={customStyles.modalBackground} onClick={closeModal} />

        <Box sx={customStyles.content}>
          <Heading as="h3" sx={customStyles.title}>
            {title}
          </Heading>
          <Heading as="h4" sx={customStyles.subtitle}>
            {subtitle}
          </Heading>
          <Text as="p" sx={customStyles.text}>
            {text}
          </Text>
          <Box sx={customStyles.body} dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
      </Box>
    )
  } else {
    return ''
  }
}

const customStyles = {
  container: {
    position: 'fixed',
    top: '0rem',
    left: '0rem',
    width: '100%',
    height: '100vh',
    zIndex: '9000'
  },
  modalBackground: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'background',
    opacity: '0.7'
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '9100',
    minWidth: '300px',
    minHeight: '400px',
    maxWidth: '90%',
    maxHeight: '90vh',
    overflowY: 'scroll',
    backgroundColor: 'background',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2rem',
    boxShadow: '2px 2px 10px grey',
    // scrollbar styles
    '::-webkit-scrollbar': {
      width: '10px'
    },
    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  },

  title: {
    variant: 'customVariants.title',
    textAlign: 'center'
  },
  subtitle: {
    variant: 'customVariants.subtitle',
    textAlign: 'center'
  },
  text: {
    variant: 'customVariants.text',
    textAlign: 'center'
  },
  body: {
    variant: 'customVariants.text',
    textAlign: 'center'
  }
}
